import vuexStore from '@/vuex'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import { ControlsAppShort, PaginationMeta } from '@aedifion.io/aedifion-api'
import { GetControlsAppsPayload, useControlsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { useAppStore } from '@/stores/app'
import { getStatusCodeMeaning } from '@/utils/helpers/controls'

type State = {
  apps: ControlsAppShort[]|null,
  appsMeta: PaginationMeta|null,
  loading: boolean,
  search: string,
  selectedControlsApp: string|null,
}

export const useAIControlsLogAppsStore = defineStore('AIControlsLogApps', () => {
  const appStore = useAppStore()
  const controlsApiStore = useControlsApiStore()

  const state: State = reactive({
    apps: null,
    appsMeta: null,
    loading: false,
    search: '',
    selectedControlsApp: null,
  })

  function clear (shouldResetSelectedAppId: boolean = true): void {
    state.apps = null
    state.appsMeta = null
    state.search = ''
    if (shouldResetSelectedAppId) {
      state.selectedControlsApp = null
    }
  }

  async function searchControlsApps (value: string): Promise<void> {
    state.search = value
    state.apps = null
    state.appsMeta = null
    await fetchMoreControlsApps()
  }

  const canFetchMoreControlsApps = computed<boolean>(() => {
    if (state.appsMeta) {
      const loadedItems = state.appsMeta.current_page * state.appsMeta.items_per_page
      return state.appsMeta.total_items > loadedItems
    } else {
      return true
    }
  })

  async function fetchMoreControlsApps (): Promise<void> {
    if (!canFetchMoreControlsApps.value || state.loading || !appStore.currentProjectControlsAppCount) {
      return
    }

    state.loading = true

    try {
      const payload: GetControlsAppsPayload = {
        page: (state.appsMeta?.current_page ?? 0) + 1,
        perPage: 40,
        projectId: appStore.projectId,
        search: state.search || undefined,
      }
      const result = await controlsApiStore.getControlsApps(payload, { storeResult: false })

      state.appsMeta = result.meta

      const apps = result.items.filter((app) => {
        return !getStatusCodeMeaning('hidden', app.status.code)
      })

      if (state.apps) {
        state.apps.push(...apps)
      } else {
        state.apps = [...apps]
      }
    } catch (error) {
      const errorMessage = i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.controls_apps'), supportEmail: texts.emailSupport })
      showErrorNotification(errorMessage)
      reportError(error)
    } finally {
      state.loading = false
    }
  }

  async function selectFirstControlsApp (): Promise<void> {
    if (state.apps !== null && state.apps?.length > 0) {
      state.selectedControlsApp = state.apps[0].id
    } else {
      state.selectedControlsApp = null
    }
  }

  function selectControlsApp (id: string): void {
    vuexStore.commit('optimization/SET_SELECTED_COMPONENT_IN_PROJECT', null)
    state.selectedControlsApp = id
  }

  return {
    clear,
    fetchMoreControlsApps,
    searchControlsApps,
    selectControlsApp,
    selectFirstControlsApp,
    state,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAIControlsLogAppsStore, import.meta.hot))
}
