<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import AlgorithmShortcode from './AlgorithmShortcode.vue'
import { getAlgorithmShortcode } from '@/utils/helpers/controls'
import { ref, onMounted, onUnmounted, watch, nextTick, computed } from 'vue'

interface Props {
  algorithm?: string,
  description?: string,
  gradientNr?: number,
  truncateDescription?: boolean,
  isActive?: boolean,
  isBigTitle?: boolean,
  isDescriptionExpanded?: boolean,
  name?: string,
  title?: string,
}

const props = withDefaults(defineProps<Props>(), {})

const hasScrollbar = ref(false)
const hasBottomBorder = ref(false)
const cardContentElement = ref<HTMLDivElement | null>(null)

onMounted(() => {
  if (cardContentElement.value) {
    cardContentElement.value.addEventListener('scroll', handleScroll)
  }
})

watch(() => props.isDescriptionExpanded, async (newValue) => {
  if (cardContentElement.value) {
    if (newValue) {
      hasBottomBorder.value = cardContentElement.value.scrollHeight > cardContentElement.value.clientHeight + 1

      await nextTick()

      if (cardContentElement.value.scrollHeight === cardContentElement.value.clientHeight) {
        hasBottomBorder.value = false
      }
    } else {
      cardContentElement.value.scrollTop = 0
      hasScrollbar.value = false
      hasBottomBorder.value = false
    }
  } else {
    hasScrollbar.value = false
    hasBottomBorder.value = false
  }
})

watch(() => props.algorithm, async () => {
  hasScrollbar.value = false
  hasBottomBorder.value = false
})

onUnmounted(() => {
  if (cardContentElement.value) {
    cardContentElement.value.removeEventListener('scroll', handleScroll)
  }
})

const { t } = useI18n()

function getAlgorithmInfo (key: 'name' | 'description') {
  if (key === 'name' && props.name) {
    return props.name
  }
  if (key === 'description' && props.description) {
    return props.description
  }

  return t(`ai_controls.algorithm.${props.algorithm}.${key}`)
}

const shortcode = computed(() => getAlgorithmShortcode(props.algorithm || ''))

const handleScroll = () => {
  if (cardContentElement.value) {
    const { scrollTop, scrollHeight, clientHeight } = cardContentElement.value

    if (props.isDescriptionExpanded) {
      if (scrollTop + clientHeight < scrollHeight && scrollTop > 0) {
        hasScrollbar.value = true
        hasBottomBorder.value = false
      } else {
        if (scrollTop === 0) {
          hasBottomBorder.value = true
        } else {
          hasBottomBorder.value = false
        }
        hasScrollbar.value = false
      }
    }
  }
}
</script>

<template>
  <v-card class="px-2 pt-4 pb-2">
    <div
      v-if="!algorithm"
      data-testid="skeleton-loader"
    >
      <v-skeleton-loader
        type="text"
        class="shortcode"
      />
      <v-skeleton-loader type="text" />
      <v-skeleton-loader
        type="text"
        class="description"
      />
    </div>
    <div
      v-else
      data-testid="algorithm-content"
    >
      <v-card-title class="tw-flex tw-justify-between tw-items-start tw-gap-2 tw-flex-wrap">
        <AlgorithmShortcode
          :shortcode="shortcode"
          :gradient-nr="gradientNr"
          :is-active="isActive"
          :data-testid="`shortcode`"
          :class="`test-is-active-${isActive}`"
        />
        <slot name="title-indicator" />
      </v-card-title>
      <v-card-subtitle
        class="tw-whitespace-normal tw-opacity-100 tw-tracking-[0.2px]"
        :class="isBigTitle ? 'tw-text-2xl tw-font-semibold text-neutral-darken4 tw-pt-2 tw-capitalize' : 'tw-text-xs text-neutral-darken1 tw-pb-2'"
        data-testid="algorithm-name"
      >
        {{ getAlgorithmInfo('name') }}
      </v-card-subtitle>
      <v-card-text
        class="tw-text-sm text-neutral-darken3"
        :style="truncateDescription ? '' : 'max-height: calc(100vh - 200px); padding-bottom: 0;'"
      >
        <div
          v-if="truncateDescription"
          class="tw-line-clamp-5 algorithm-description"
          v-html="getAlgorithmInfo('description')"
        />
        <div
          v-else
          ref="cardContentElement"
          :class="['expandable-description', { 'scrolling': hasScrollbar }, { 'bottom-border': hasBottomBorder }, { 'tw-line-clamp-5': !isDescriptionExpanded }]"
          @scroll="handleScroll"
          v-html="getAlgorithmInfo('description')"
        />
      </v-card-text>
      <slot name="actions" />
    </div>
  </v-card>
</template>

<style lang="sass" scoped>
:deep(.v-skeleton-loader)
  &.shortcode
    .v-skeleton-loader__text
      @apply tw-h-7
  &.description
    .v-skeleton-loader__text
      @apply tw-h-40
:deep(.algorithm-description)
  p
    &:not(:last-child)
       margin-bottom: 8px !important
:deep(.v-card-actions)
  padding-top: 0 !important
  .v-btn
    border-color: rgb(var(--v-theme-primary-lighten2)) !important
    .v-btn__content
      @apply tw-leading-5

:deep(.expandable-description)
  overflow-y: auto
  max-height: calc(100vh - 500px)
  transition: border 0.3s ease
  p
    &:not(:last-child)
       margin-bottom: 8px !important

.expandable-description.scrolling
  border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1))
  border-top: 1px solid rgb(var(--v-theme-neutral-lighten1))

.expandable-description.bottom-border
  border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1))
 </style>
