<script setup lang="ts">
import { computed, ref } from 'vue'
import Highcharts from 'highcharts'
import LineChart from '@/components/Charts/LineChart.vue'
import { mapLineChartSeriesToXRange } from '@/filters/highcharts'
import { useI18n } from 'vue-i18n'
import { VUETIFY_COLORS } from '@theme/colors'
import XRangeChart from '@/components/Charts/XRangeChart.vue'
import { ZoomParameters } from './types'

interface Props {
  customLineChartOptions?: Highcharts.ChartOptions,
  customXRangeChartOptions?: Highcharts.ChartOptions,
  isZoomed?: boolean,
  loading?: boolean,
  series: Highcharts.SeriesOptionsType[],
  xMax: Date,
  xMin: Date
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  customLineChartOptions: () => ({}),
  customXRangeChartOptions: () => ({}),
  isZoomed: false,
  loading: false,
})

const emit = defineEmits<{
  (e: 'timeseries-viewer:reset-zoom'): void;
  (e: 'timeseries-viewer:zoom', params: ZoomParameters): void;
}>()

const { t } = useI18n()

const binarySeries = computed(() => {
  return props.series.filter((seriesElement) => {
    return (seriesElement.custom as CustomSeriesData).isBinary === true && seriesElement.visible
  })
    .reverse()
    // @ts-ignore
    .map(mapLineChartSeriesToXRange)
})
const analogSeries = computed(() => {
  return props.series.filter((seriesElement) => {
    return (seriesElement.custom as CustomSeriesData).isBinary === false && seriesElement.visible
  })
})

const shouldBinaryChartHaveMargin = computed(() => {
  return !analogSeries.value.length || !binarySeries.value.length
})

const shouldBinaryChartHaveTicks = computed(() => {
  return !analogSeries.value.length && !!binarySeries.value.length
})

const analogCursorPosition = ref<number | null>(null)
const binaryCursorPosition = ref<{x1: number, x2: number} | null>(null)

function setAnalogCursorPosition (value: number|null) {
  analogCursorPosition.value = value
}

function setBinaryCursorPosition (value: { x1: number, x2: number }|null) {
  binaryCursorPosition.value = value
}

const plotLines = computed(() => {
  if (analogCursorPosition.value === null) {
    return []
  }
  return [{
    color: VUETIFY_COLORS.neutral.base,
    value: analogCursorPosition.value,
    width: 1,
    zIndex: 2,
  }]
})

const plotBands = computed(() => {
  if (binaryCursorPosition.value === null) {
    return []
  }
  return [{
    color: VUETIFY_COLORS.neutral.lighten3,
    from: binaryCursorPosition.value.x1,
    to: binaryCursorPosition.value.x2,
  }]
})

function resetZoom () {
  emit('timeseries-viewer:reset-zoom')
}

function zoom (zoomParameters: ZoomParameters) {
  emit('timeseries-viewer:zoom', zoomParameters)
}
</script>

<script lang="ts">
export type CustomSeriesData = {
  isBinary: boolean;
  unit: string;
}

export const BAR_WIDTH = 6
export const BAR_GAP = 12

export const PLOT_MARGIN_X_AXIS = 50
export const PLOT_MARGIN_Y_AXIS = 80
export const PLOT_MARGIN_FREE = 10
export const PLOT_MINIMUM_HEIGHT = 70
</script>

<template>
  <div class="timeseries-viewer">
    <div class="d-flex justify-end mb-2">
      <v-btn
        :disabled="!props.isZoomed"
        @click="resetZoom"
      >
        {{ t('highcharts.reset_zoom') }}
      </v-btn>
    </div>
    <XRangeChart
      v-if="binarySeries.length"
      :custom-options="props.customXRangeChartOptions"
      :plot-bands="plotBands"
      :plot-lines="plotLines"
      :series="binarySeries"
      :should-have-margin="shouldBinaryChartHaveMargin"
      :should-have-ticks="shouldBinaryChartHaveTicks"
      :x-max="props.xMax"
      :x-min="props.xMin"
      @x-range-chart:reset-zoom="resetZoom"
      @x-range-chart:zoom="zoom($event)"
      @x-range-chart:reset-cursor="setBinaryCursorPosition(null)"
      @x-range-chart:set-cursor="setBinaryCursorPosition($event)"
    />
    <LineChart
      v-show="!binarySeries.length || analogSeries.length"
      :custom-options="props.customLineChartOptions"
      :loading="props.loading"
      :plot-bands="plotBands"
      :plot-lines="plotLines"
      :series="analogSeries"
      :x-max="props.xMax"
      :x-min="props.xMin"
      @line-chart:reset-zoom="resetZoom"
      @line-chart:zoom="zoom($event)"
      @line-chart:reset-cursor="setAnalogCursorPosition(null)"
      @line-chart:set-cursor="setAnalogCursorPosition($event)"
    />
  </div>
</template>
