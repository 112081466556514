import { ControlsApp, ControlsAppPins } from '@aedifion.io/aedifion-api'
import { GetControlsAppsPayload, PostControlsAppRunPayload, useControlsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { acceptHMRUpdate, defineStore } from 'pinia'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { useAIControlsAppPinStore } from '@/stores/views/AIControls/App/pin'
import { useAppStore } from '@/stores/app'
import { getStatusCodeMeaning } from '@/utils/helpers/controls'

export const POLLING_TIMEOUT = 10000

export const useAIControlsAppStore = defineStore('AIControlsApp', () => {
  const router = useRouter()
  const appStore = useAppStore()
  const controlsApiStore = useControlsApiStore()
  const aiControlsAppPinStore = useAIControlsAppPinStore()
  const controlsApp = ref<ControlsApp>()
  const isLoading = ref(false)

  const pollingTimeoutId = ref<number | null>(null)

  watch(() => appStore.projectId, () => {
    controlsApp.value = undefined
  })

  async function fetchApp (appId: string): Promise<void> {
    // might be needed if the current app changed
    stopAppPolling()

    if (!appStore.currentProjectControlsAppCount || appId === controlsApp.value?.id) {
      return
    }

    isLoading.value = true
    try {
      if (!appId) {
        appId = await _fetchRandomAppId() ?? ''
      }

      controlsApp.value = await controlsApiStore.getControlsApp({
        projectId: appStore.projectId,
        controlsAppId: appId,
      })

      if (getStatusCodeMeaning('transient', controlsApp.value.status.code)) {
        _startAppPolling()
      }

      await aiControlsAppPinStore.fetchPinValues(_getPinIds(controlsApp.value))
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.controls_app'), supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      isLoading.value = false
    }
  }

  function _getPinIds (controlsApp: ControlsApp): string[] {
    const appPinIds: string[] = []
    const pins = controlsApp.pins
    if (pins) {
      (Object.keys(pins) as Array<keyof ControlsAppPins>).forEach((pinCollection) => {
        pins[pinCollection]!.forEach((pin) => {
          if (pin.dataPointID) {
            appPinIds.push(pin.dataPointID)
          }
        })
      })
    }
    return appPinIds
  }

  // TODO: fetch the "first" non-hidden app instead of a random one
  async function _fetchRandomAppId (): Promise<string|undefined> {
    if (!appStore.currentProjectControlsAppCount) return
    const payload: GetControlsAppsPayload = {
      page: Math.floor(Math.random() * appStore.currentProjectControlsAppCount) + 1,
      perPage: 1,
      projectId: appStore.projectId,
    }
    const controlsAppsFetchResult = await controlsApiStore.getControlsApps(payload)
    const appId = controlsAppsFetchResult?.items[0]?.id
    router.replace({ path: `/ai-controls/app/${appStore.projectId}/${appId}` })
    return appId
  }

  async function toggleAppRunningState () {
    if (!controlsApp.value || getStatusCodeMeaning('disabled', controlsApp.value.status.code) || getStatusCodeMeaning('hidden', controlsApp.value.status.code)) {
      return
    }

    stopAppPolling()

    const shouldRun = !getStatusCodeMeaning('active', controlsApp.value.status.code)
    try {
      const payload: PostControlsAppRunPayload = {
        controlsAppId: controlsApp.value?.id,
        projectId: appStore.projectId,
        run: shouldRun,
      }
      controlsApp.value.status = (await controlsApiStore.postControlsAppRun(payload)).status

      if (getStatusCodeMeaning('transient', controlsApp.value.status.code)) {
        _startAppPolling()
      }
    } catch (error) {
      const action = shouldRun ? i18n.global.t('actions.activated') : i18n.global.t('actions.deactivated')
      showErrorNotification(`${i18n.global.t('notifications.errors.optimization.toggle', { action, supportEmail: texts.emailSupport })}`)
      reportError(error)
    }
  }

  function stopAppPolling () {
    if (pollingTimeoutId.value) {
      clearTimeout(pollingTimeoutId.value)
      pollingTimeoutId.value = null
    }
  }

  function _startAppPolling () {
    if (!pollingTimeoutId.value) {
      pollingTimeoutId.value = setTimeout(_pollApp, POLLING_TIMEOUT)
    }
  }

  async function _pollApp () {
    if (controlsApp.value) {
      stopAppPolling()

      try {
        controlsApp.value = await controlsApiStore.getControlsApp({
          projectId: appStore.projectId,
          controlsAppId: controlsApp.value.id,
        })
      } catch {
        // fail silently
      }

      if (getStatusCodeMeaning('transient', controlsApp.value.status.code)) {
        _startAppPolling()
      }
    }
  }

  return {
    controlsApp,
    fetchApp,
    isLoading,
    stopAppPolling,
    toggleAppRunningState,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAIControlsAppStore, import.meta.hot))
}
