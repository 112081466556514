import { Texts } from '../types.js'

export default {
  appName: 'aedifion.io',
  companyName: 'aedifion',
  docs: {
    variables: {
      domain: 'https://docs.aedifion.io',
      products: {
        analytics: 'analytics',
        controls: 'controls',
        edge_device: 'edge-device',
        elevate: 'elevate',
        engineering: 'engineering',
        io: 'io'
      }
    }
  },
  emailSupport: 'support@aedifion.com',
  phoneSupport: '+49 221 98650770',
  supportCallToActionKey: 'support_call_to_action.ask'
} as Texts
