<i18n locale="de">
{
  "follow_analysis_period": "Analyseperiode folgen",
  "no_mapped_pins": "Keine Datenpunkte gesetzt."
}
</i18n>
<i18n locale="en">
{
  "follow_analysis_period": "Follow analysis period",
  "no_mapped_pins": "No datapoints mapped."
}
</i18n>

<template>
  <div
    class="trendview mt-0"
  >
    <v-sheet class="trendview-sheet px-2 pt-4">
      <div
        class="d-flex flex-row justify-space-between align-start"
        :class="showFollowAnalysisPeriodCheckbox ? 'tw-mb-2': 'tw-mb-4'"
      >
        <div>
          <SamplerateSelect
            :disabled="!hasTimeseries"
            :value="samplerate"
            @update:value="updateSamplerate"
          />
        </div>
        <div class="d-flex flex-column justify-end align-end">
          <PlottingDateRangePicker
            :value="dateRange"
            @datepicker:change="setDateRange"
          />
          <v-checkbox
            v-if="showFollowAnalysisPeriodCheckbox"
            v-model="followAnalysisPeriod"
            class="mt-1 mr-2"
            color="primary"
            density="compact"
            hide-details
            data-testid="follow-analysis-period-checkbox"
            :label="t('follow_analysis_period')"
          />
        </div>
      </div>
      <TimeseriesViewer
        v-if="!isDatapointsTableUpdate"
        :custom-line-chart-options="customChartOptions"
        :is-zoomed="!!zoom"
        :loading="loading"
        :series="seriesForChart"
        :x-max="effectiveRange.end"
        :x-min="effectiveRange.start"
        @timeseries-viewer:reset-zoom="zoomChart"
        @timeseries-viewer:zoom="zoomChart"
      />
    </v-sheet>
    <DatapointsTable
      v-if="showDatapointsTable"
      :datapoint-table-items="datapointTableItems"
      :deselection-enabled="false"
      items-have-no-description
      :loading="loading"
      :label-getter="$store.getters['labels/label']"
      :menu-enabled="false"
      :no-data-text="t('no_mapped_pins')"
      data-testid="datapoints-table"
      @datapoints-table:set-all-series-visibility="onSetAllTimeseriesVisibility"
      @datapoints-table:toggle-series="toggleTimeseries"
      @datapoints-table:before-update="isDatapointsTableUpdate = true"
      @datapoints-table:updated="isDatapointsTableUpdate = false"
    />
  </div>
</template>

<script lang="ts">
import Highcharts from 'highcharts'
import { AnalyticsResultsForComponentResultIds, TagAssociation, TimeseriesWithContext } from '@aedifion.io/aedifion-api'
import { calculateAverage, convertTimeseriesToPlottableData, fetchProjectTimeseries, getMinAndMaxValuesOfTimeSeries } from '@/utils/helpers/timeseries'
import { DateRange, DateRangeObject, Samplerate, TimeseriesShort } from '@/vuex/data_points_view/types'
import DatapointsTable from '@/components/DatapointsTable.vue'
import { DatapointTableItem } from '@/vuex/datapoints/types'
import { defineComponent } from 'vue'
import { formatSubAndSuper } from '@/filters/formatting'
import { getDatePartAsString } from '@/utils/helpers/dates'
import { getLeastUsedSeriesColor } from '@/utils/helpers/visualization'
import { getStartAndEndDate } from '@/utils/helpers/timerange'
import { mapGetters } from 'vuex'
import moment from 'moment'
import PlottingDateRangePicker from '@/components/PlottingDateRangePicker.vue'
import SamplerateSelect from '@/components/SamplerateSelect.vue'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { TIMESERIES_COLORS } from '@theme/colors'
import TimeseriesViewer from '@/components/Charts/TimeseriesViewer.vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'
import { ZoomParameters } from '@/components/Charts/types'

type Timeseries = {
  data: TimeseriesShort<number>;
  dataPointID: string;
  isBinary: boolean;
  tags: Array<TagAssociation>;
  unitLabelId: string;
}

export default defineComponent({
  name: 'TrendView',

  components: {
    DatapointsTable,
    PlottingDateRangePicker,
    SamplerateSelect,
    TimeseriesViewer,
  },

  props: {
    showDatapointsTable: {
      type: Boolean,
      default: true,
    },
    showFollowAnalysisPeriodCheckbox: {
      type: Boolean,
      default: false,
    },
  },

  setup () {
    const { t } = useI18n()

    const userStore = useUserStore()
    const userDetails = userStore.userDetails

    return {
      t,
      userDetails,
    }
  },

  data () {
    return {
      dateRange: [
        getDatePartAsString(moment.utc().subtract(7, 'd')),
        getDatePartAsString(moment.utc()),
      ] as DateRange,
      hiddenTimeseries: [] as string[],
      ignoreZoomEvents: false,
      loadingTimeseries: false,
      samplerate: 'auto' as Samplerate,
      timeseries: null as null|Timeseries[],
      timeseriesColorMap: new Map(),
      zoom: null as DateRangeObject|null,
      isDatapointsTableUpdate: false,
    }
  },

  computed: {
    ...mapGetters({
      mappedPinNames: 'optimization/selectedComponentsMappedPinNames',
    }),

    customChartOptions (): Highcharts.Options {
      return {
        lang: {
          noData: this.t('no_mapped_pins') as string,
        },
      }
    },

    datapointTableItems (): DatapointTableItem[] {
      return (this.timeseries || []).map((currentTimeseries: Timeseries) => {
        const dataPointID = currentTimeseries.dataPointID
        const minMaxTimeSeriesValues = getMinAndMaxValuesOfTimeSeries(currentTimeseries.data as Array<Array<number>> ?? [])
        const meanValue = calculateAverage(currentTimeseries.data ?? [])
        const unitLabel = currentTimeseries.unitLabelId ? this.$store.getters['labels/label']('units', currentTimeseries.unitLabelId) : null
        return {
          dataPointID,
          max: minMaxTimeSeriesValues.max,
          mean: meanValue,
          min: minMaxTimeSeriesValues.min,
          seriesColor: this.timeseriesColorMap.get(dataPointID),
          tags: currentTimeseries.tags,
          title: this.mappedPinNames.get(dataPointID),
          unit: unitLabel?.display_name,
          visible: !this.hiddenTimeseries.includes(dataPointID),
        }
      })
    },

    effectiveRange (): DateRangeObject {
      return getStartAndEndDate(this.dateRange, this.zoom || undefined)
    },

    followAnalysisPeriod: {
      get (): boolean {
        return this.$store.getters['optimization/followAnalysisPeriod']
      },
      set (value: boolean): void {
        this.$store.commit('optimization/SET_FOLLOW_ANALYSIS_PERIOD', value)
        if (value) {
          this.matchDateRangeToAnalysis()
        }
      },
    },

    hasTimeseries (): boolean {
      return this.timeseries !== null && this.timeseries.length > 0
    },

    loading (): boolean {
      return this.$store.getters['optimization/isLoadingComponentInProject'] || this.loadingTimeseries
    },

    seriesForChart (): unknown {
      const result = []
      for (const currentTimeseries of this.timeseries || []) {
        let unitLabel = null
        if (currentTimeseries.unitLabelId) {
          unitLabel = this.$store.getters['labels/label']('units', currentTimeseries.unitLabelId)
        }
        const unit = unitLabel ? formatSubAndSuper(unitLabel.symbol) : undefined

        const dataPointID = currentTimeseries.dataPointID
        if (!this.hiddenTimeseries.includes(dataPointID)) {
          result.push({
            color: this.timeseriesColorMap.get(dataPointID),
            custom: { isBinary: currentTimeseries.isBinary, unit },
            data: currentTimeseries.data,
            name: this.mappedPinNames.get(dataPointID),
            visible: true,
          })
        }
      }
      return result
    },
  },

  watch: {
    '$store.state.analysis_instances.analysisResult' (): void {
      if (this.followAnalysisPeriod) {
        this.matchDateRangeToAnalysis()
      }
    },

    '$store.state.optimization.selectedComponentInProjectResults' (): void {
      if (this.followAnalysisPeriod) {
        this.matchDateRangeToAnalysis()
      }
    },

    mappedPinNames (): void {
      this.resetData()
      this.fetchTimeseries()
    },
  },

  created () {
    this.fetchTimeseries()
  },

  methods: {
    assignTimeseriesColor (dataPointID: string): void {
      if (!this.timeseriesColorMap.has(dataPointID)) {
        const nextAvailableColor: string = getLeastUsedSeriesColor(this.timeseriesColorMap, TIMESERIES_COLORS)
        this.timeseriesColorMap.set(dataPointID, nextAvailableColor)
      }
    },

    async convertGetTimeseriesResult (analogTimeseries: TimeseriesWithContext[], binaryTimeseries: TimeseriesWithContext[]): Promise<void> {
      this.timeseries = []
      for (const timeseriesWithContext of binaryTimeseries) {
        this.timeseries.push({
          data: convertTimeseriesToPlottableData(timeseriesWithContext.data!),
          dataPointID: timeseriesWithContext.dataPointID!,
          isBinary: true,
          tags: timeseriesWithContext.tags!,
          unitLabelId: timeseriesWithContext.units!,
        })
        this.assignTimeseriesColor(timeseriesWithContext.dataPointID!)
      }
      for (const timeseriesWithContext of analogTimeseries) {
        this.timeseries.push({
          data: convertTimeseriesToPlottableData(timeseriesWithContext.data!),
          dataPointID: timeseriesWithContext.dataPointID!,
          isBinary: false,
          tags: timeseriesWithContext.tags!,
          unitLabelId: timeseriesWithContext.units!,
        })
        this.assignTimeseriesColor(timeseriesWithContext.dataPointID!)
      }
    },

    async fetchTimeseries (): Promise<void> {
      if (this.mappedPinNames === null || this.mappedPinNames.size === 0) {
        return
      }
      this.loadingTimeseries = true
      try {
        const projectID: number = this.$store.getters['projects/currentProjectId']!
        const { start, end }: DateRangeObject = this.effectiveRange

        const hashIds: string[] = this.$store.getters['optimization/selectedComponentsMappedPinHashIds']
        const [hashIdsForAnalogTimeseries, hashIdsForBinaryTimeseries]: [string[], string[]] = await this.$store.dispatch('data_points_view/separateByTimeseriesType', hashIds)

        const resultingTimeseries = await Promise.all([
          hashIdsForAnalogTimeseries.length > 0
            ? fetchProjectTimeseries(
              projectID,
              hashIdsForAnalogTimeseries,
              this.userDetails?.units_system,
              this.userDetails?.currency_system,
              start,
              end,
              this.samplerate,
              true,
            )
            : undefined,
          hashIdsForBinaryTimeseries.length > 0
            ? fetchProjectTimeseries(
              projectID,
              hashIdsForBinaryTimeseries,
              undefined,
              undefined,
              start,
              end,
              undefined, // no samplerate for binary datapoints
              true,
            )
            : undefined,
        ])

        const analogTimeseries = resultingTimeseries[0] ?? []
        const binaryTimeseries = resultingTimeseries[1] ?? []

        this.convertGetTimeseriesResult(analogTimeseries, binaryTimeseries)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        showErrorNotification(error.message || this.t('notifications.errors.data_points_view.fetchTimeseries.error') as string)
      } finally {
        this.loadingTimeseries = false
      }
    },

    matchDateRangeToAnalysis (): void {
      let newStart, newEnd: string|undefined
      const selectedAnalysisResult = this.$store.state.analysis_instances.analysisResult
      if (selectedAnalysisResult) {
        newStart = getDatePartAsString(selectedAnalysisResult.start!)
        newEnd = getDatePartAsString(selectedAnalysisResult.end!)
      } else {
        const allResults: AnalyticsResultsForComponentResultIds[] = this.$store.getters['optimization/getSelectedComponentResults']
        if (allResults.length > 0) {
          newStart = getDatePartAsString(allResults[0].start!)
          newEnd = getDatePartAsString(allResults[0].end!)
        }
      }
      if (newStart && newEnd && newStart !== this.dateRange[0] && newEnd !== this.dateRange[1]) {
        this.setDateRange([newStart, newEnd])
      }
    },

    onSetAllTimeseriesVisibility (visible: boolean): void {
      this.hiddenTimeseries = []
      if (!visible) {
        for (const item of this.datapointTableItems) {
          this.hiddenTimeseries.push(item.dataPointID)
        }
      }
    },

    resetData (): void {
      this.dateRange = [
        getDatePartAsString(moment.utc().subtract(7, 'd')),
        getDatePartAsString(moment.utc()),
      ]
      this.hiddenTimeseries = []
      this.timeseries = null
      this.timeseriesColorMap = new Map()
      this.zoom = null
    },

    resetSamplerate (): void {
      this.samplerate = 'auto'
    },

    resetZoom (): void {
      this.zoom = null
    },

    setDateRange (dates: string[]) {
      this.resetZoom()
      this.dateRange = dates.length === 2 ? [dates[0], dates[1]] : [dates[0]]
      this.fetchTimeseries()
    },

    toggleTimeseries (dataPointID: string): void {
      const index = this.hiddenTimeseries.indexOf(dataPointID)
      if (index > -1) {
        this.hiddenTimeseries.splice(index, 1)
      } else {
        this.hiddenTimeseries.push(dataPointID)
      }
    },

    updateSamplerate (samplerate: Samplerate): void {
      this.samplerate = samplerate
      this.fetchTimeseries()
    },

    zoomChart (zoomParams?: ZoomParameters) {
      if (!this.ignoreZoomEvents) {
        if (zoomParams) {
          const zoom = {
            end: new Date(zoomParams.end),
            start: new Date(zoomParams.start),
          }
          if (!this.zoom || zoom.start !== this.zoom.start || zoom.end !== this.zoom.end) {
            this.zoom = zoom
            this.fetchTimeseries()
          }
        } else {
          this.resetZoom()
          this.fetchTimeseries()
        }
      }
    },
  },
})
</script>
