<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import {
  type CustomSeriesData,
  PLOT_MARGIN_FREE,
  PLOT_MARGIN_X_AXIS,
  PLOT_MARGIN_Y_AXIS,
} from './TimeseriesViewer.vue'
import { type PlotBand, type PlotLine, type ZoomParameters } from './types'
import { TIMESERIES_COLORS, VUETIFY_COLORS } from '@theme/colors'
import { Chart } from 'highcharts-vue'
import { formatValue } from '@/filters/formatting'
import Highcharts from 'highcharts'
import { merge } from 'lodash'
import moment from 'moment'
import nodata from 'highcharts/modules/no-data-to-display'
import { TYPOGRAPHY } from '@/utils/designConstants'
import { useI18n } from 'vue-i18n'

interface Props {
  customOptions?: Highcharts.ChartOptions,
  loading?: boolean,
  plotBands: PlotBand[],
  plotLines: PlotLine[],
  series: Highcharts.SeriesOptionsType[],
  xMax: Date,
  xMin: Date
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  customOptions: () => ({}),
  loading: false,
})

const emit = defineEmits<{
  (e: 'line-chart:reset-zoom'): void;
  (e: 'line-chart:zoom', params: ZoomParameters): void;
  (e: 'line-chart:reset-cursor'): void;
  (e: 'line-chart:set-cursor', params: number): void;
}>()

const { t } = useI18n()

const chart = ref<typeof Chart|null>(null)

watch(
  () => props.loading,
  (newVal) => {
    if (newVal === true) {
      chart.value?.chart.showLoading()
    } else {
      chart.value?.chart.hideLoading()
    }
  },
)

// --- execution ---

nodata(Highcharts)

/**
 * https://github.com/highcharts/highcharts-vue/issues/71#issuecomment-485784315
 * The 'lang' object can not be updated to force a re-draw. Languages only change on rendering (create/destroy hooks)
 */
Highcharts.setOptions({
  accessibility: {
    enabled: false,
  },
  chart: {
    style: {
      fontFamily: "'Inter', sans-serif",
    },
  },
  lang: {
    loading: t('highcharts.loading'),
    noData: t('highcharts.no_data'),
    shortWeekdays: [
      t('dates.short_weekdays.sunday'),
      t('dates.short_weekdays.monday'),
      t('dates.short_weekdays.tuesday'),
      t('dates.short_weekdays.wednesday'),
      t('dates.short_weekdays.thursday'),
      t('dates.short_weekdays.friday'),
      t('dates.short_weekdays.saturday')],
  },
  noData: {
    style: {
      color: VUETIFY_COLORS.neutral.darken2,
      fontSize: TYPOGRAPHY.headings.body1.size,
      fontWeight: TYPOGRAPHY.headings.body1.weight,
    },
  },
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },
})

const chartOptions = computed(() => {
  const result: Highcharts.Options = {
    chart: {
      events: {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        selection: (event: any) => {
          if (event.resetSelection) {
            emit('line-chart:reset-zoom')
          } else {
            const newStartTime: number = event.xAxis[0].min
            const newEndTime: number = event.xAxis[0].max
            emit('line-chart:zoom', {
              end: newEndTime,
              start: newStartTime,
            } as ZoomParameters)
          }
          return false
        },
      },
      margin: [
        PLOT_MARGIN_FREE,
        PLOT_MARGIN_FREE,
        PLOT_MARGIN_X_AXIS,
        PLOT_MARGIN_Y_AXIS,
      ],
      // TODO: this should be checked
      // @ts-expect-error Highcharts type definitions are different from the implementation
      step: 'left',
      type: 'line',
      zoomType: 'x',
    },
    colors: TIMESERIES_COLORS,
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    loading: {
      // @ts-ignore
      useHTML: true,
    },
    noData: {
      useHTML: true,
    },
    plotOptions: {
      line: {
        animation: false,
      },
      series: {
        marker: {
          enabled: false,
        },
        point: {
          events: {
            mouseOut: () => {
              emit('line-chart:reset-cursor')
            },
            mouseOver: (mouseOverEvent: Event) => {
              emit('line-chart:set-cursor', (mouseOverEvent.target as unknown as Highcharts.Point).x)
            },
          },
        },
        // TODO: this should be checked
        // @ts-expect-error Highcharts type definitions are different from the implementation
        step: true,
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: `${VUETIFY_COLORS.neutral.lighten5}ce`,
      borderColor: VUETIFY_COLORS.neutral.lighten1,
      borderRadius: 4,
      borderWidth: 1,
      formatter () {
        const ctx = this
        const custom = ctx.series.userOptions.custom as CustomSeriesData
        const offset = moment(ctx.x).utcOffset() / 60
        const offsetString = offset > 0 ? `+${offset}` : `${offset}`
        const date = moment(ctx.x).format('DD.MM.YYYY, HH:mm:ss')
        const value = formatValue(ctx.y, { fallbackValue: '', subAndSuperUnit: true, unit: custom.unit })

        return `${date} <b>UTC${offsetString}</b><br>${ctx.series.name}: <b>${value}</b>`
      },
      shadow: false,
      style: {
        color: VUETIFY_COLORS.neutral.darken3,
      },
      useHTML: true,
    },
    xAxis: {
      dateTimeLabelFormats: {
        day: t('dates.chart.weekday_date_format'),
      },
      gridLineColor: VUETIFY_COLORS.neutral.lighten2,
      gridLineDashStyle: 'ShortDash',
      gridLineWidth: 1,
      labels: {
        style: {
          color: VUETIFY_COLORS.neutral.darken3,
          fontSize: TYPOGRAPHY.headings.legend.size,
          fontWeight: TYPOGRAPHY.headings.legend.weight,
        },
      },
      lineColor: VUETIFY_COLORS.neutral.darken3,
      max: props.xMax.valueOf(),
      min: props.xMin.valueOf(),
      plotBands: props.plotBands,
      plotLines: props.plotLines,
      tickColor: VUETIFY_COLORS.neutral.darken3,
      type: 'datetime',
    },
    yAxis: {
      allowDecimals: false,
      gridLineColor: VUETIFY_COLORS.neutral.lighten2,
      labels: {
        style: {
          color: VUETIFY_COLORS.neutral.darken3,
          fontSize: TYPOGRAPHY.headings.legend.size,
          fontWeight: TYPOGRAPHY.headings.legend.weight,
        },
      },
      tickColor: VUETIFY_COLORS.neutral.darken3,
      title: {
        style: {
          color: VUETIFY_COLORS.neutral.darken3,
          fontSize: TYPOGRAPHY.headings.legend.size,
          fontWeight: TYPOGRAPHY.headings.legend.weight,
        },
        text: t('highcharts.values'),
      },
    },
  }
  merge(result, props.customOptions)
  // This is done after creating the object to ensure that the series comes from the prop, not from customOptions
  Object.assign(result, {
    series: props.series,
  })
  return result
})
</script>

<template>
  <Chart
    ref="chart"
    class="chart"
    :options="chartOptions"
    :update-args="[true, true, true]"
  />
</template>

<style lang="sass" scoped>
.chart
  min-height: 400px
</style>
